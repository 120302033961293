import React from "react"

import { Helmet } from 'react-helmet'

import LordsMobileImage from "../components/lordsmobileimage"
import CoatOfArmsImage from "../components/coatofarmsimage"
import LordsMobileVideo from "../components/lordsmobilevideo"

import Carousel from 'react-bootstrap/Carousel'

const TITLE = 'Fun Videos';

const FunVideosPage = () => (
  <div>
    
    <Helmet>
      <title>{ TITLE }</title>
    </Helmet>
    
    <div class="flex-container">

      <div class = "flex-child">
        <br/>
        <br/>
        <br/>
        <LordsMobileImage/>
      </div>

      <div class="flex-child textstyling">
        <br/>
        <h1>Our Favorite Fun Videos</h1>
      </div>

      <div class = "flex-child">
        <Carousel>
            <Carousel.Item>
              <LordsMobileVideo
                videoSrcURL="https://www.youtube.com/embed/XIEais8wDOo"
                videoTitle="Lords Mobile IRL"
              />
            </Carousel.Item>
            <Carousel.Item>
              <LordsMobileVideo
                videoSrcURL="https://www.youtube.com/embed/wZQM0wksX8Q"
                videoTitle="A New Emperor Is Crowned In Lords Mobile"
              />
            </Carousel.Item>
            
        </Carousel>
      </div>

      <div class="flex-child">
        <br/>
        <CoatOfArmsImage/>
      </div>

    </div>

  </div>
)
  


export default FunVideosPage