import React from "react"

import { Helmet } from 'react-helmet'

import LordsMobileImage from "../components/lordsmobileimage"
import CoatOfArmsImage from "../components/coatofarmsimage"

const TITLE = '404';

const Error404Page = () => (
  <div>
    
    <Helmet>
      <title>{ TITLE }</title>
    </Helmet>

    <div class="flex-container">

      <div class = "flex-child">
        <br/>
        <br/>
        <br/>
        <LordsMobileImage/>
      </div>

      <div class="flex-child textstyling">
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>

      <div class="flex-child">
        <iframe title="Monty Python Gify"src="https://giphy.com/embed/7cAGURf0zN2Bq" width="480" height="480" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
      </div>

      <div class="flex-child">
        <br/>
          <CoatOfArmsImage/>
      </div>

    </div>

  </div>
)
    
  


export default Error404Page