import React from "react"

import { Helmet } from 'react-helmet'

import LordsMobileImage from "../components/lordsmobileimage"
import CoatOfArmsImage from "../components/coatofarmsimage"
import LordsMobileVideo from "../components/lordsmobilevideo"

import Carousel from 'react-bootstrap/Carousel'

const TITLE = 'Strategy Videos';

const StrategyVideosPage = () => (
  <div>

    <Helmet>
      <title>{ TITLE }</title>
    </Helmet>

    <div class="flex-container">
      <div class = "flex-child">
        <br/>
        <br/>
        <br/>
        <LordsMobileImage/>
      </div>

      <div class="flex-child textstyling">
        <br/>
        <h1>Our Favorite Strategy Videos</h1>
      </div>

      <div class = "flex-child">
        <Carousel>
            <Carousel.Item>
                <LordsMobileVideo
                videoSrcURL="https://www.youtube.com/embed/OQ6c6M-W7-k"
                videoTitle="How To Win Guild Showdown In Lords Mobile"
                />
            </Carousel.Item>
            <Carousel.Item>
                <LordsMobileVideo
                videoSrcURL="https://www.youtube.com/embed/_EwKQMvgQos"
                videoTitle="How To Build A Deadly Solo Trap In Lords Mobile"
                />
            </Carousel.Item>
            <Carousel.Item>
                <LordsMobileVideo
                videoSrcURL="https://www.youtube.com/embed/OUEhw8MWe3A"
                videoTitle="How To Build A Deadly Rally Trap In Lords Mobile"
                />
            </Carousel.Item>
        </Carousel>
      </div>

      <div class="flex-child">
        <br/>
        <CoatOfArmsImage/>
      </div>

    </div>

  </div>
)
  


export default StrategyVideosPage