import React from "react"

import "./marksangryreviewimagelink.css"

import Image from "../images/marksangryreview.jpg"

const MarksAngryReviewImageLink = () =>
(
  <a href="https://www.marksangryreview.com">
    <img src={Image} className="marksangryreviewimagelink" alt="Mark's Angry Review Logo"/>
  </a>
  
)

export default MarksAngryReviewImageLink
