import React from "react"

import { Helmet } from 'react-helmet'

import LordsMobileImage from "../components/lordsmobileimage"
import CoatOfArmsImage from "../components/coatofarmsimage"

import Card from "react-bootstrap/Card"
import Accordion from "react-bootstrap/Accordion"
import Spinner from "react-bootstrap/Spinner"

const TITLE = 'Heroic Blades [HBD]';

const HomePage = () => (
  <div>
    
    <Helmet>
      <title>{ TITLE }</title>
    </Helmet>

    <div class="flex-container">

      <div class = "flex-child">
        <br/>
        <br/>
        <br/>
        <LordsMobileImage/>
      </div>

      <div class="flex-child textstyling">
        <br/>
        <Accordion defaultActiveKey="0">
        <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              We Are Heroic Blades [HBD]
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <p class="pdark">We are a fun loving international guild that likes to rock out! </p>
                <p class="pdark">By helping newer players grow, we all get stronger.  Come join our family.</p>
        
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              Our History
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <p class="pdark">Caddis started this guild to play with his sons and father-in-law. </p>
                <p class="pdark">We've been growing and having fun ever since :)</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              What We Do
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <p class="pdark">We participate in the following guild events:</p>
                <p class="pdark">Guild Fest</p>
                <p class="pdark">Kingdom Clash</p>
                <p class="pdark">Guild Showdown</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="3">
              <Spinner animation="grow" size="sm" />
              Requirements
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                <p class="pdark">We are currently an open guild with <span class="textboldhighlight">NO MIGHT REQUIREMENTS</span>.</p>
                <p class="pdark">We are stil growing and have  <span class="textboldhighlight">NOT SET MINIMUM MONSTER HUNTING OR GUILD FEST REQUIREMENTS</span>.</p>
                <p class="pdark">We ask that folks participate in the events above and <span class="textboldhighlight"> MONSTER HUNT WHEN THEY CAN TO HELP THE GUILD GROW</span>.</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="4">
              Join Us!
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                <p class="pdark">Join our guild by searching for <span class="textboldhighlight">Heroic Blades or the HBD guild tag</span>.</p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

        </Accordion>
      </div>

      <div class="flex-child">
        <br/>
        <CoatOfArmsImage/>
      </div>

    </div>

  </div>
)
  


export default HomePage