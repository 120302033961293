import React from "react"

import { Helmet } from 'react-helmet'

import LordsMobileImage from "../components/lordsmobileimage"
import CoatOfArmsImage from "../components/coatofarmsimage"
import LordsMobileVideo from "../components/lordsmobilevideo"
import GooglePlayImage from "../components/googleplayimagelink"
import AppleStoreImage from "../components/applestoreimagelink"
import LordsMobileCraftImageLink from "../components/lordsmobilecraftimagelink"
import MarksAngryReviewImageLink from "../components/marksangryreviewimagelink"

import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import Spinner from "react-bootstrap/Spinner"


const TITLE = 'About Lords Mobile';

const AboutLordsMobilePage = () => (
  <div>

    <Helmet>
      <title>{ TITLE }</title>
    </Helmet>
    


    <div class="flex-container">

      <div class = "flex-child">
        <br/>
        <br/>
        <br/>
        <LordsMobileImage/>
      </div>

      <div class="flex-child textstyling">
        <br/>
        <Accordion defaultActiveKey="0">
        <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              Where To Download Lords Mobile
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>  
                <GooglePlayImage/>
                <p></p>
                <AppleStoreImage/>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
            <Spinner animation="grow" size="sm" />
              Check Out These Great Websites
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <LordsMobileCraftImageLink/>
                <p></p>
                <MarksAngryReviewImageLink/>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
      
      <div class="flex-child">
        <br/>
        <LordsMobileVideo
          videoSrcURL="https://www.youtube.com/embed/ptUlOH9vrsQ"
          videoTitle="Lords Mobile Trailer on YouTube"
        />
      </div>

      <div class="flex-child">
        <br/>
        <CoatOfArmsImage/>
      </div>

    </div>

  </div>
)
    
  


export default AboutLordsMobilePage