import React from "react"

import "./coatofarmsimage.css"

import Image from "../images/coatofarms.png"

const CoatOfArmsImage = () =>
(
  <img src={Image} className="coatofarmsimage" alt="Coat Of Arms Logo"/>
)

export default CoatOfArmsImage
