import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import GA from './utilities/googleanalytics'

import HomePage from "./pages/home"
import AboutLordsMobilePage from "./pages/aboutlordsmobile";
import FunVideosPage from "./pages/funvideos";
import StrategyVideosPage from "./pages/strategyvideos";
import Error404Page from "./pages/404";

import NavigationBar from "./components/navigationbar"
import Footer from "./components/footer"


export default function App() {
  return (
    <div>
      <NavigationBar siteTitle="Heroic Blades [HBD]"/>

      <Router>
        
        <div>
          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */
              GA.init() && <GA.RouteTracker />}
          <Switch>
            <Route exact path="/" component={HomePage}/>
            <Route exact path="/aboutlordsmobile" component={AboutLordsMobilePage}/>
            <Route exact path="/funvideos" component={FunVideosPage}/>
            <Route exact path="/strategyvideos" component={StrategyVideosPage}/>
            <Route component={Error404Page}/>
          </Switch>
        </div>
      </Router>
      
      <br/>
      <Footer siteTitle="Heroic Blades [HBD]"/>

    </div>
  );
}


