import React, { useState } from 'react';

import PropTypes from "prop-types"

import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Form from "react-bootstrap/Form"
import FormControl from "react-bootstrap/FormControl"
import ListGroup from "react-bootstrap/ListGroup"
import ListGroupItem from "react-bootstrap/ListGroupItem"

import Fuse from 'fuse.js'
import characters  from '../searchdata/searchdata.json';

import "./navigationbar.css"

const fuse = new Fuse(characters,{
        keys: [
            'search'
        ]
    }
);


const NavigationBar = ({ siteTitle } ) =>
{
    var results = fuse.search('');

    var searchResults = results.map(character => character.item);

    const [searchResultsToDisplay, setSearchResultsToDisplay] = useState([]);

    const handleChange = (e) => {
        results = fuse.search(e.target.value);
        searchResults = results.map(character => character.item);
        setSearchResultsToDisplay(searchResults);
    };

    return(
        <div>
            <Navbar collapseOnSelect expand="lg" variant="dark" fixed="top" className="navigationbar">
                <Navbar.Brand href="/">{siteTitle}</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav>
                        <Nav.Link href="/aboutlordsmobile">About Lords Mobile</Nav.Link>
                        <Nav.Link href="/strategyvideos">Strategy Videos</Nav.Link>
                        <Nav.Link href="/funvideos">Fun Videos</Nav.Link>
                        <Nav.Link href="http://lordsmobile.igg.com/">Lords Mobile IGG Website</Nav.Link>
                    </Nav>
                    <Nav className="ml-auto">
                        <Form inline className="FormStyle">
                                <FormControl type="text" placeholder="Search" className="mr-sm-2" onChange={handleChange}></FormControl>/>
                                <ListGroup variant='flush'>
                                    {searchResultsToDisplay.map(searchResult => {
                                            const { search, page } = searchResult;
                                            return (
                                                <ListGroupItem action href={page}>{search}</ListGroupItem>
                                            )
                                        })}
                                </ListGroup>
                            </Form>
                    </Nav>
                </Navbar.Collapse>
                
            </Navbar>
            
        </div>
    )
}

NavigationBar.propTypes = {
    siteTitle: PropTypes.string,
  }
  
NavigationBar.defaultProps = {
    siteTitle: ``,
  }

export default NavigationBar;