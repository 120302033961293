import React from "react"

import "./applestoreimagelink.css"

import Image from "../images/applestore.png"

const AppleStoreImageLink = () =>
(
  <a href="https://apps.apple.com/us/app/lords-mobile-kingdom-wars/id1071976327">
    <img src={Image} className="applestoreimagelink" alt="Apple Store Logo Link to Lords Mobile Download"/>
  </a>
  
)

export default AppleStoreImageLink
