import React from "react"

import "./lordsmobilecraftimagelink.css"

import Image from "../images/lordsmobilecraft.png"

const LordsMobileCraftImageLink = () =>
(
  <a href="https://www.lordsmobilecraft.com">
    <img src={Image} className="lordsmobilecraftimagelink" alt="Lords Mobile Craft Logo"/>
  </a>
  
)

export default LordsMobileCraftImageLink
