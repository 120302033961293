import React from "react"

import "./lordsmobileimage.css"

import Image from "../images/lordsmobile.png"

const LordsMobileImage = () =>
(
  <img src={Image} className="lordsmobileimage" alt="Lords Mobile Logo"/>
)

export default LordsMobileImage
