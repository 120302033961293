import React from "react"

import "./googleplayimagelink.css"

import Image from "../images/googleplay.png"

const GooglePlayImageLink = () =>
(
  <a href="https://play.google.com/store/apps/details?id=com.igg.android.lordsmobile&hl=en_US">
    <img src={Image} className="googleplayimagelink" alt="Google Play Logo Link to Lords Mobile Download"/>
  </a>
  
)

export default GooglePlayImageLink
