import PropTypes from "prop-types"
import React from "react"

import "./footer.css"

const Footer = ({ siteTitle }) => (
  <footer class = "footer">
    <div>
      <p class="footer">
        {siteTitle}
          {` `} © {new Date().getFullYear()}, built with
          {` `} love
      </p>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
